import { createAction, props } from '@ngrx/store';
import { Element } from './element.model';

const elementActionLabel = '[Element]';

export enum ElementActionType {
  GET_ALL_ELEMENTS = `${elementActionLabel} Get All Elements`,
  GET_ALL_ELEMENTS_SUCCESS = `${elementActionLabel} Get All Elements :: Success`,
  GET_ELEMENT = `${elementActionLabel} Get Element`,
  GET_ELEMENT_SUCCESS = `${elementActionLabel} Get Element :: Success`,
  CREATE_ELEMENT = `${elementActionLabel} Create Element`,
  CREATE_ELEMENT_SUCCESS = `${elementActionLabel} Create Element :: Success`,
  UPDATE_ELEMENT = `${elementActionLabel} Update Element`,
  UPDATE_ELEMENT_SUCCESS = `${elementActionLabel} Update Element :: Success`,
  DELETE_ELEMENT = `${elementActionLabel} Delete Element`,
  DELETE_ELEMENT_SUCCESS = `${elementActionLabel} Delete Element :: Success`,
  RESET_ELEMENT_UPDATED = `${elementActionLabel} Reset Element Updated`,
  RESET_ELEMENT_DELETED = `${elementActionLabel} Reset Element Deleted`,
}

export const getAllElements = createAction(ElementActionType.GET_ALL_ELEMENTS);
export const getAllElementsSuccess = createAction(
  ElementActionType.GET_ALL_ELEMENTS_SUCCESS,
  props<{ elements: Element[] }>()
);
export const getElement = createAction(ElementActionType.GET_ELEMENT, props<{ id: number }>());
export const getElementSuccess = createAction(
  ElementActionType.GET_ELEMENT,
  props<{ element: Element }>()
);
export const createElement = createAction(
  ElementActionType.CREATE_ELEMENT,
  props<{ element: Element }>()
);
export const createElementSuccess = createAction(
  ElementActionType.CREATE_ELEMENT_SUCCESS,
  props<{ element: Element }>()
);
export const updateElements = createAction(
  ElementActionType.UPDATE_ELEMENT,
  props<{ elements: Element[] }>()
);
export const updateElementsSuccess = createAction(
  ElementActionType.UPDATE_ELEMENT_SUCCESS,
  props<{ elements: Element[] }>()
);
export const deleteElement = createAction(
  ElementActionType.DELETE_ELEMENT,
  props<{ id: number }>()
);
export const deleteElementSuccess = createAction(ElementActionType.DELETE_ELEMENT_SUCCESS);
export const resetElementUpdated = createAction(ElementActionType.RESET_ELEMENT_UPDATED);
export const resetElementDeleted = createAction(ElementActionType.RESET_ELEMENT_DELETED);
